// import "./plans.css"
import { PropTypes } from "prop-types"
import React, { useState } from "react"
import { ButtonLink } from "../../common/ui/button-link"
import {
  Button,
  Card,
  Classes,
  EditableText,
  H4,
  Icon,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Position,
  Spinner,
} from "@blueprintjs/core"
import { PlanCreateButton } from "./plan-create-button"

export const PlanTitleForm = ({ onSubmit, onCancel }) => {
  const [text, setText] = useState("")

  return (
    <Card>
      <EditableText
        confirmOnEnterKey={true}
        placeholder="Name your plan!"
        value={text}
        onChange={setText}
        onCancel={onCancel}
        onConfirm={() => onSubmit(text)}
      />
    </Card>
  )
}

PlanTitleForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

PlanTitleForm.defaultProps = {
  onSubmit: async () => null,
  onCancel: async () => null,
}

const PlanCellContextMenu = ({ items }) => {
  if (items == null || items.length === 0) {
    return null
  }
  return (
    <Menu>
      {items.map((item, index) => {
        return <MenuItem key={index} text={item.text} {...item} />
      })}
    </Menu>
  )
}

const DEFAULT_LOCATION = "—"
const DEFAULT_PERIOD = "—"
export const PlanCell = ({
  id,
  title,
  location = DEFAULT_LOCATION,
  period = DEFAULT_PERIOD,
  onDelete,
  participationAccepted,
  onArchiveOrRestore,
  archived,
}) => {
  const [contextMenuShowing, setContextMenuShowing] = useState(false)
  const toggleContextMenu = () => setContextMenuShowing(!contextMenuShowing)

  const unarchivedActions = [
    {
      text: "Archive This Plan",
      intent: Intent.DANGER,
      icon: "archive",
      onClick: () =>
        onArchiveOrRestore(id).then(() => setContextMenuShowing(false)),
    },
  ]

  const archivedActions = [
    {
      text: "Restore This Plan",
      intent: Intent.SUCCESS,
      onClick: () =>
        onArchiveOrRestore(id).then(() => setContextMenuShowing(false)),
    },
  ]

  const iconSize = 12

  return (
    <Card className="plan-cell">
      <div>
        <H4 className="plan-cell-title flex-1 m-0">{title}</H4>
        <p className="m-0">
          <small className="plan-cell-location">
            <Icon icon="map-marker" iconSize={iconSize} /> {location}
          </small>
          <small className="plan-cell-period">
            <Icon icon="calendar" iconSize={iconSize} /> {period}
          </small>
        </p>
        <div style={{ textAlign: "right" }}>
          {participationAccepted ? (
            <div className="text-right">
              <ButtonLink intent="primary" to={{ pathname: "/plans/[id]", query: { id }}}>
                See Dashboard
              </ButtonLink>
            </div>
          ) : (
            <ButtonLink intent="primary" to={{ pathname: "/plans/[id]/join", query: { id }}}>
              Accept Plan
            </ButtonLink>
          )}
        </div>
      </div>

      {participationAccepted ? (
        <div className="plan-cell-menu-toggle">
          <Popover
            position={Position.BOTTOM_RIGHT}
            isOpen={contextMenuShowing}
            content={
              <PlanCellContextMenu
                items={archived ? archivedActions : unarchivedActions}
            />
          }>
            <Button minimal icon="more" active={contextMenuShowing} onClick={toggleContextMenu} />
          </Popover>
        </div>
      ) : (
        <div></div>
      )}
    </Card>
  )
}

export const PlanList = ({ plans = [], onArchiveOrRestore }) => {
  const planCells = plans.map((plan) => {
    return (
      <li key={plan.id}>
        <PlanCell {...plan} onArchiveOrRestore={onArchiveOrRestore} />
      </li>
    )
  })

  return <ul className="mt-4 p-0">{planCells}</ul>
}

export const EmptyPlanList = ({ isArchiveViewActive }) => {
  return isArchiveViewActive ? (
    <div className="mt-4 plan-list--empty">
      <NonIdealState
        title="No plans in archive"
        description="You dont have any archived plans"
        className="mt-1"
      />
    </div>
  ) : (
    <div className="mt-4 plan-list--empty">
      <NonIdealState
        title="You don't have any plans yet"
        description="Click the create button to get to planning!"
        className="mt-1"
      />
    </div>
  )
}

export const LoadingPlanList = () => {
  return (
    <div className="plan-list--loading">
      <NonIdealState
        icon={<Spinner />}
        title="Hold on a sec, fetching your plans..."
      />
    </div>
  )
}

export const Plans = ({
  plans,
  isLoading,
  archiveOrRestore,
  isArchiveViewActive,
  onCreatePlan,
}) => {
  const [createFormVisible, setCreateFormVisible] = useState(false)
  const showCreateForm = () => setCreateFormVisible(true)
  const hideCreateForm = () => setCreateFormVisible(false)

  const createPlan = (title) => onCreatePlan({ title }).then(hideCreateForm)

  const controls = isArchiveViewActive ? (
    <ButtonLink
      to={{ pathname: "" }}
      icon="archive"
      fill
    >
      Archive
    </ButtonLink>
  ) : createFormVisible ? (
    <PlanTitleForm onSubmit={createPlan} onCancel={hideCreateForm} />
  ) : (
    <div className="flex">
      <PlanCreateButton fill={true} onClick={showCreateForm} />
      <ButtonLink
        to={{ pathname: "", query: { archived: true } }}
        icon="archive"
        className="ml-2"
      >
        Archive
      </ButtonLink>
    </div>
  )

  return (
    <>
      {controls}
      {isLoading && !plans?.length ? (
        <div className={[Classes.SKELETON, "mt-4"].join(" ")}>
          <PlanCell />
        </div>
        ) : null}
      {!isLoading && (plans == null || plans.length === 0) ? (
        <EmptyPlanList isArchiveViewActive={isArchiveViewActive} />
      ) : (
        <PlanList plans={plans} onArchiveOrRestore={archiveOrRestore} />
      )}
    </>
  )
}
