import { restoreArchived } from "../framework/actions"

export const RestorePlan = (apiRestorePlan, dispatch) => async (planId) => {

  if (planId == null) {
    return false
  }

  await apiRestorePlan(planId)
  dispatch(restoreArchived(planId))
  return true
}
