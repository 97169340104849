import { removePlan } from "../framework/actions"

export const ArchivePlan = (apiArchivePlan, dispatch) => async ( planId ) => {

  if (planId == null) {
    return false
  }

  await apiArchivePlan(planId)
  dispatch(removePlan(planId))
  return true
}
