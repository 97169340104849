// import "./plans-panel.css"
import { PropTypes } from "prop-types"
import React, { useEffect, useState } from "react"
import { H3 } from "@blueprintjs/core"
import Container from "../../common/ui/container"
import { Header } from "../../common/ui/header"
import { Plans } from "./plans"

export const PlansPanel = ({
  plans,
  archivedPlans,
  onRequestPlans,
  onCreatePlan,
  onArchivePlan,
  onRestorePlan,
  showArchived,
}) => {
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    // TODO: UC-4 - if not a guest, pass in the plannerId, if it exists
    setLoading(true)
    onRequestPlans({}).then(() => setLoading(false))
  }, [])

  return (
    <>
      <Header title="Plans" />
      <Container>
        {showArchived ? <H3>Archived Plans</H3> : <H3>Your Plans</H3>}
        <Plans
          isLoading={isLoading}
          plans={showArchived ? archivedPlans : plans}
          onCreatePlan={onCreatePlan}
          archiveOrRestore={showArchived ? onRestorePlan : onArchivePlan}
          isArchiveViewActive={showArchived}
        />
      </Container>
    </>
  )
}

PlansPanel.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object),
  onRequestPlans: PropTypes.func,
  onCreatePlan: PropTypes.func,
  onArchivePlan: PropTypes.func,
}

PlansPanel.defaultProps = {
  plans: [],
  onRequestPlans: async () => [],
  onCreatePlan: async () => null,
  onArchivePlan: async () => null,
}
