import { User } from "../domain/user"
import { actions } from "../framework/actions"

export const GetUser = (apiGetUser, dispatch) => async () => {
  const userData = await apiGetUser()
  const user = new User(userData)

  dispatch(actions.doGetUser({ user }))
  return user
}

export default GetUser
