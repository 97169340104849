import { compose } from "lodash/fp"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import Link from "next/link"
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core"
import { ApiContext } from "../../common/framework/api-context"
import { withContext } from "../../common/framework/context"
import config from "../../config"
import { Initials } from "../../plan/ui/avatar"
import { GetUser } from "../use-cases/get-user"
import { LogoutUser } from "../use-cases/logout-user"

const UserBadgeMenu = ({ onLogout }) => (
  <Menu>
    <li>
      <Link href="/plans" passHref>
        <a className="bp5-menu-item bp5-icon-airplane" tabIndex="0">My Plans</a>
      </Link>
    </li>
    <MenuItem href={config.appSupportLink} icon="help" text="Support" />
    <MenuItem onClick={onLogout} icon="log-out" text="Log Out" />
  </Menu>
)

const LoggedInUserBadge = ({ user, onLogout }) => (
  <Popover content={<UserBadgeMenu onLogout={onLogout} />}>
    <Button minimal>
      <Initials userId={user.id} />
    </Button>
  </Popover>
)

const UserBadge = ({ user, accessTokens, onLogout, onGetUser }) => {
  // TODO: hoist the loading up into an auth manager (along with maybe an auto-refresh)
  useEffect(() => {
    if (!user.id && accessTokens.access) {
      onGetUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return user.id ? (
    <LoggedInUserBadge user={user} onLogout={onLogout} />
  ) : (
    <div>
      <Link href="/auth/login/">Log In</Link> or{" "}
      <Link href="/auth/register/">Register</Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  accessTokens: state.login.accessTokens,
  user: state.login.user,
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onLogout: LogoutUser(dispatch),
  onGetUser: GetUser(api.getUser, dispatch),
})

export default compose(
  withContext(ApiContext, "api"),
  connect(mapStateToProps, mapDispatchToProps)
)(UserBadge)
