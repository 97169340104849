// import "./avatar.css"
import React from "react"
import { connect } from "react-redux"
import { selectUserById } from "../framework/selectors"

const getUserInitials = (user = { first_name: "N", last_name: "A" }) =>
  user.first_name && user.last_name
    ? `${user.first_name[0]}${user.last_name[0]}`
    : "NA"

const _Initials = ({ user }) => (
  <span className="avatar">{getUserInitials(user)}</span>
)

const _Email = ({ user }) => <span>{user && user.email}</span>

const mapStateToUserProps = (state, { userId }) => ({
  user: selectUserById(userId)(state),
})
const withUser = connect(mapStateToUserProps)

export const Initials = withUser(_Initials)
export const Email = withUser(_Email)
