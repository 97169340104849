import Fuse from "fuse.js"
import _ from "lodash/fp"

export const selectMyPlans = (state) => {
  return Object.values(_.pick(state.plans.byArchived[false], state.plans.byId))
}
export const selectArchivedPlans = (state) => {
  return Object.values(_.pick(state.plans.byArchived[true], state.plans.byId))
}

export const selectTaggedItemIds = (tags, planId) => (state) => {
  const itemIdsToQuery = tags.reduce(
    (planItemIdCollection, tag) =>
      _.intersection(planItemIdCollection, state.planItems.byTagId[tag]),
    state.planItems.byPlanId[planId]
  )

  return itemIdsToQuery
}

export const selectTextFilteredItemIds = (filter, planId) => (state) => {
  if (!filter || filter.trim().length === 0) {
    return state.planItems.byPlanId[planId]
  }

  const getSearchOptions = _.get(`planItems.searchOptions`)
  const selectItemsByIds = (planId) => (state) =>
    Object.values(
      _.pick(state.planItems.byPlanId[planId], state.planItems.byId)
    )
  const indexByPlanId = (planId) =>
    _.get(`planItems.search.indexByPlanId[${planId}]`)
  const index = indexByPlanId(planId)(state)
    ? Fuse.parseIndex(indexByPlanId(planId)(state))
    : null

  if (index == null) {
    return state.planItems.byPlanId[planId]
  }

  const items = selectItemsByIds(planId)(state)
  const options = getSearchOptions(state)
  const fuse = new Fuse(items, options, index)

  return fuse.search(filter).map((ref) => ref.item.id)
}

const selectEntitiesForIds = (ids) => _.flow(_.pick(ids), Object.values)

const selectorByNamedIndex = (slice, indexName) => (keyInIndex) =>
  _.flow(
    (state) => state[slice],
    (slicedState) => {
      const index = slicedState[indexName]
      const entityIds = index[keyInIndex] || []
      return selectEntitiesForIds(entityIds)(slicedState.byId)
    }
  )

export const selectPlanItemsForPlanId = selectorByNamedIndex(
  "planItems",
  "byPlanId"
)
export const selectPlanUsersForPlanId = selectorByNamedIndex(
  "planUsers",
  "byPlanId"
)
export const selectPlanUsersByUserId = selectorByNamedIndex(
  "planUsers",
  "byUserId"
)

// this gets the user from the first planUser with that id.
// While the`user` objects should be the same for each PlanUser that relates a particular user,
// this could be simpler by just storing unique Users in state.
export const selectUserById = (id) =>
  _.flow(
    _.flow(selectorByNamedIndex("planUsers", "byUserId")([id]), _.first),
    _.get("user")
  )

export const selectPlanExpensesForPlanId = selectorByNamedIndex(
  "planExpenses",
  "byPlanId"
)

// TODO: maybe load this from BE or do this cleaner somehow
export const getNumberOfTaggedItems = (state) => (tagId) =>
  _.getOr(0, `planItems.byTagId[${tagId}].length`)(state)

export const selectTagsForPlanId = (planId) => (state) =>
  (selectorByNamedIndex("planTags", "byPlanId")(planId)(state))
    .map((tag) => ({
      ...tag,
      associatedCount: tag.associatedCount ?? getNumberOfTaggedItems(state)(tag.id)
    }))

export const selectTagsForPlanItemId = selectorByNamedIndex(
  "planTags",
  "byItemId"
)

export const selectCommitsForPlanItemId = selectorByNamedIndex(
  "planCommits",
  "byItemId"
)

export const getUserHasCommitted = (itemId, userId) =>
  _.flow(selectCommitsForPlanItemId(itemId), (commits) =>
    commits.some((commit) => commit.userId === userId)
  )

export const isWithin = (item, items) => items.some(i => i === item)

export const selectPlansParticipation = selectorByNamedIndex(
  "planUsers",
  "byId"
)

export const selectCalendarEvents = selectorByNamedIndex(
  "planCalendarEvents",
  "byId"
)

export const getDatesbyCalendarId = (calendarId) => (state) => {
  if (!calendarId) return []

  if (!state.planCalendarEvents.dateByCalendarId[calendarId]) {
    return []
  }

  return state.planCalendarEvents.dateByCalendarId[calendarId]
    .map((date) => new Date(date + "T00:00:00"))
    .sort((a, b) => a - b)
}

export const getEventsbyDate = (date, calendarId) => {
  return _.flow(
    (state) => state.planCalendarEvents,
    (slicedState) =>
      selectEntitiesForIds(
        _.intersection(
          slicedState["byDate"][date],
          slicedState["byCalendarId"][calendarId]
        ) || []
      )(slicedState.byId),
    (events) =>
      events.sort((a, b) => a.startTime.getHours() - b.startTime.getHours())
  )
}

export const getSortedCommentsByItemId = selectorByNamedIndex(
  "planComments",
  "byItemId"
)

export const selectCalendarsbyPlan = selectorByNamedIndex(
  "planCalendars",
  "byPlanId"
)

export const selectItemsForFilters = (filters, planId) => (state) => {
  const filteredItemIds = filters
    .map((filter) => filter(state))
    .reduce(_.intersection, state.planItems.byPlanId[planId])

  return selectEntitiesForIds(filteredItemIds)(state.planItems.byId)
}
