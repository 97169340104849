import { createOrUpdatePlans } from "../framework/actions"

export const GetPlans = (apiGetPlans, dispatch) => async ({
  plannerId = null,
}) => {
  const plans = await apiGetPlans({ plannerId })
  dispatch(createOrUpdatePlans(plans.map((plan) => plan.toJSON())))
  return plans
}

export default GetPlans
