import { Plan } from "../domain/plan"
import { createOrUpdatePlan } from "../framework/actions"

export const CreatePlan = (apiCreatePlan, dispatch) => async ({ title }) => {
  if (title == null || title.length === 0 || title.trim() === "") {
    return null
  }

  const createdPlan = await apiCreatePlan(new Plan({ title }))

  if (!createdPlan) {
    return null
  }
  dispatch(createOrUpdatePlan(createdPlan.toJSON()))
  return createdPlan
}

export default CreatePlan
