import React from "react"
import Link from "next/link"
import { withRouter } from "next/router"
import { AnchorButton } from "@blueprintjs/core"

const isActive = (href, router) => {
  return typeof href == "string" ? router.asPath === href : (href.pathname === router.pathname && href.query === router.query)
}

export const ButtonLink = withRouter(({ to: href, router, ...rest }) => (
  <Link href={href} passHref>
    <AnchorButton intent={isActive(href, router) ? "primary": null} minimal {...rest} />
  </Link>
))