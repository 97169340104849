import { withRouter } from "next/router"
import { connect } from "react-redux"
import { selectMyPlans } from "../framework/selectors"
import { selectArchivedPlans } from "../framework/selectors"
import { CreatePlan } from "../use-cases/create-plan"
import { ArchivePlan } from "../use-cases/archive-plan"
import { GetPlans } from "../use-cases/get-plans"
import { PlansPanel } from "./plans-panel"
import { RestorePlan } from "../use-cases/restore-plan"
import { compose } from "lodash/fp"
import { withContext } from "../../common/framework/context"
import { ApiContext } from "../../common/framework/api-context"

const mapStateToProps = (state, { router }) => ({
  showArchived: router.query.archived,
  plans: selectMyPlans(state),
  archivedPlans: selectArchivedPlans(state),
})

const mapDispatchToProps = (dispatch, { api }) => ({
  onRequestPlans: GetPlans(api.getPlans, dispatch),
  onCreatePlan: CreatePlan(api.createPlan, dispatch),
  onArchivePlan: ArchivePlan(api.archivePlan, dispatch),
  onRestorePlan: RestorePlan(api.archivePlan, dispatch),
})

export default compose(
  withContext(ApiContext, "api"),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlansPanel)
