import React from "react"
import { Button } from "@blueprintjs/core"

const DEFAULT_TEXT = "Create Plan"
export const PlanCreateButton = ({
  onClick,
  text = DEFAULT_TEXT,
  ...props
}) => {
  return (
    <Button {...props} icon="add" intent="primary" onClick={onClick}>
      {text}
    </Button>
  )
}
