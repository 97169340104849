import React from "react"
import { Helmet } from "react-helmet"
import Link from "next/link"
import { Alignment, Navbar } from "@blueprintjs/core"
import { config } from "../../config"
import UserBadge from "../../login/ui/user-badge"

export const Header = ({ title }) => (
  <Navbar>
    <Helmet title={title} />
    <Navbar.Group align={Alignment.LEFT}>
      <Navbar.Heading>
        <Link href="/">{config.appName}</Link>
      </Navbar.Heading>
    </Navbar.Group>
    <Navbar.Group align={Alignment.RIGHT}>
      <Navbar.Divider />
      <UserBadge />
    </Navbar.Group>
  </Navbar>
)

export default Header
